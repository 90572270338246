import styled from "@mui/material/styles/styled";
import { Paragraph } from "components/Typography";
import { layoutConstant } from "utils/constants"; 
import Card from "@mui/material/Card";
// USED IN ALL PRODUCTS & CAROUSELS COMPONENT

export const SubTitle = styled(Paragraph)(({
  theme
}) => ({
  fontSize: 12,
  marginTop: "-20px",
  marginBottom: "20px",
  color: theme.palette.grey[600]
})); 
// USED IN PAGE VIEW

export const StyledCard = styled(Card)(({
  theme
}) => ({
  height: "100%",
  display: "flex",
  boxShadow: "none",
  alignItems: "center",
  padding: "20px 50px",
  justifyContent: "center",
  background: theme.palette.paste[50],
  [theme.breakpoints.down("sm")]: {
    padding: "20px 30px",
    "& h3": {
      fontSize: 20
    }
  }
})); 

export const ContentWrapper = styled("div")(({
  theme
}) => ({
  display: "flex",
  position: "relative",
  ".sidebar": {
    width: "100%",
    height: "100%",
    position: "sticky",
    top: layoutConstant.headerHeight + 15,
    maxWidth: layoutConstant.grocerySidenavWidth,
    [theme.breakpoints.down("md")]: {
      display: "none"
    }
  },
  ".content": {
    width: "100%",
    paddingLeft: "2rem",
    maxWidth: `calc(100% - ${layoutConstant.grocerySidenavWidth}px)`,
    [theme.breakpoints.down("md")]: {
      maxWidth: "100%",
      paddingLeft: 0
    }
  }
}));