"use client";

import { Fragment, useEffect, useState } from "react";
import axios from "../../../utils/axiosInstance"; 
// GLOBAL CUSTOM COMPONENTS

import StickyWrapper from "components/sticky-wrapper";
// Local CUSTOM COMPONENTS

import Section1 from "../section-1";
import Section2 from "../section-2";
import Section6 from "../section-6";

// CUSTOM DATA MODELS

// =====================================================
export default function GroceryOnePageView(props) {

  return <div className="bg-white">
      {
      /* TOP HERO AREA */
    }
      <Section1 />

      {
      /* SERVICE AREA 
      <Section2 services={props.serviceList} />
      */
      }

      <Section6 />

      {
      /* POPUP NEWSLETTER FORM */
    }
      {/* <Newsletter image="/assets/images/newsletter/bg-2.png" /> */}

    </div>;
}