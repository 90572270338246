import { useState } from "react";
import { useRouter } from "next/navigation";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

// GLOBAL CUSTOM COMPONENTS

import { H1 } from "components/Typography"; 
// STYLED COMPONENT

import { SectionContainer, SearchOutlinedIcon } from "./styles";
export default function Section1() {

  const [searchQuery, setSearchQuery] = useState("");
  const router = useRouter();

  const handleSearch = () => {
    if (searchQuery.trim()) {
      // Redirect to a new page with the query as a URL parameter
      router.push(`products/search/${encodeURIComponent(searchQuery)}`);
    }
  };

  const handleKeyDown = e => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const SEARCH_BUTTON = <Button color="primary" onClick={handleSearch} disableElevation variant="contained" sx={{
    px: "2rem",
    height: "100%",
    borderRadius: "0 8px 8px 0"
  }}>
      Search
    </Button>;
  const STYLED = {
    height: 50,
    paddingRight: 0,
    color: "grey.700",
    background: "white",
    "& fieldset": {
      border: "none"
    }
  };
  return <SectionContainer>
      <H1 maxWidth={600} mx="auto">
        Find all half-priced products, start big saving now!
      </H1>

      <div className="searchBox">
        <TextField fullWidth placeholder="Searching for..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onKeyDown={handleKeyDown}
        InputProps={{
        sx: STYLED,
        endAdornment: SEARCH_BUTTON,
        startAdornment: <SearchOutlinedIcon fontSize="small" />
      }} />

      
      </div>
    </SectionContainer>;
}