import Link from "next/link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
// GLOBAL CUSTOM COMPONENTS

import FitHeightImage from "components/FitHeightImage";
import { H3, Paragraph } from "components/Typography"; 
// STYLED COMPONENT

import { StyledCard } from "../styles"; 
// API FUNCTIONS

export default function SectionShops() {
  return <Container className="pt-6 pb-4">
    <Grid container spacing={3}>
        <Grid item md={6} sm={12} xs={12}>
          <Link href="/products/from/woolworth">
            <StyledCard>
              <Box width={{
            xs: "60%",
            xl: "50%"
          }}>
                <Paragraph fontWeight={600}>Half price</Paragraph>

                <H3 fontSize={25} lineHeight={1.35}>
                Woolworths
                </H3>

                <Button sx={{
              mt: 2
            }} color="primary" variant="outlined">
                  Shop Now
                </Button>
              </Box>

              <Box width={{
            xs: "40%",
            xl: "50%"
          }}>
                <FitHeightImage width={200} height={128} alt="Woolworths" src="/assets/images/logos/pngegg.png" />
              </Box>
            </StyledCard>
          </Link>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Link href="/products/from/coles">
            <StyledCard>
              <Box width={{
            xs: "60%",
            xl: "50%"
          }}>
                <Paragraph fontWeight={600}>Half price</Paragraph>

                <H3 fontSize={25} lineHeight={1.35}>
                Coles
                </H3>

                <Button sx={{
              mt: 2
            }} color="primary" variant="outlined">
                  Shop Now
                </Button>
              </Box>

              <Box width={{
            xs: "40%",
            xl: "50%"
          }}>
                <FitHeightImage width={200} height={58} alt="Coles" src="/assets/images/logos/coles-logo.png" />
              </Box>
            </StyledCard>
          </Link>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Link href="/products/from/priceline">
            <StyledCard>
              <Box width={{
            xs: "60%",
            xl: "50%"
          }}>
                <Paragraph fontWeight={600}>Half price</Paragraph>

                <H3 fontSize={25} lineHeight={1.35}>
                Priceline
                </H3>

                <Button sx={{
              mt: 2
            }} color="primary" variant="outlined">
                  Shop Now
                </Button>
              </Box>

              <Box width={{
            xs: "40%",
            xl: "50%"
          }}>
                <FitHeightImage width={200} height={68} alt="Priceline" src="/assets/images/logos/priceline-logo.png" />
              </Box>
            </StyledCard>
          </Link>
        </Grid>

        <Grid item md={6} sm={12} xs={12}>
          <Link href="/products/from/chemist">
            <StyledCard>
              <Box width={{
            xs: "60%",
            xl: "50%"
          }}>
                <Paragraph fontWeight={600}>Half price</Paragraph>

                <H3 fontSize={25} lineHeight={1.35}>
                Chemist warehouse
                </H3>

                <Button sx={{
              mt: 2
            }} color="primary" variant="outlined">
                  Shop Now
                </Button>
              </Box>

              <Box width={{
            xs: "40%",
            xl: "50%"
          }}>
                <FitHeightImage width={200} height={68} alt="Chemist" src="/assets/images/logos/CWH_logo_2.png" />
              </Box>
            </StyledCard>
          </Link>
        </Grid>
    </Grid>
    </Container>
}